import { ChangeEvent, FunctionComponent, useState } from 'react';
import classnames from 'classnames';

import InputWrapper, { ValidationActions } from 'components/layout/forms/InputWrapper';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutFormsInput: FunctionComponent<Props> = ({
    formik,
    className,
    name,
    label,
    helper,
    value,
    onChange,
    style,
    styles,
    disabled,
    validationAction,
    decoratorLeft,
    decoratorRight,
    expandable,
    error,
    required,
    disableDefaultRequiredBehaviour = false,
    labelOnClick,
}) => {
    const errorMessage = formik?.errors[name]?.toString() || error;
    const [currentLabel, setCurrentLabel] = useState<string>(label);

    return (
        <StyledComponent
            className={classnames(
                'layout-forms-checkbox',
                className,
                [`style-${style}`],
                {
                    'error': Boolean(errorMessage),
                    'decorator-left': Boolean(
                        errorMessage && validationAction === ValidationActions.Decorator ||
                        decoratorLeft && decoratorLeft.visible !== false
                    ),
                    'decorator-right': Boolean(decoratorRight && decoratorRight.visible !== false),
                    'disabled': disabled,
                }
            )}
            style={styles}
        >
            <InputWrapper
                name={name}
                helper={helper}
                error={errorMessage}
                validationAction={ValidationActions.None}
                decoratorLeft={decoratorLeft}
                decoratorRight={decoratorRight}
            >
                <label
                    htmlFor={name}
                    className="checkbox-wrapper"
                >
                    <input
                        id={name}
                        name={name}
                        className="checkbox"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            formik?.setFieldError(name, null);
                            onChange(e);
                        }}
                        type="checkbox"
                        value={(typeof value === 'boolean') ? value?.toString() : value}
                        checked={Boolean(value)}
                        disabled={disabled}
                        required={disableDefaultRequiredBehaviour ? false : required}
                    />
                    <span className="checkbox-trigger" />
                </label>
                <span
                    className={classnames('label', { 'clicked': labelOnClick })}
                    onClick={() => labelOnClick?.()}
                >
                    <span dangerouslySetInnerHTML={{ __html: currentLabel || label }} />
                    {expandable?.enabled && currentLabel !== expandable.label && (
                        <span
                            className="expand-trigger"
                            onClick={() => setCurrentLabel(expandable.label)}
                        >
                            {expandable.triggerText || 'Rozwiń'}
                        </span>
                    )}
                </span>
            </InputWrapper>
            {errorMessage && required && (
                <div className="error-container">
                    <div
                        className="error-message"
                        dangerouslySetInnerHTML={{ __html: errorMessage }}
                    />
                </div>
            )}
        </StyledComponent>
    );
};

export default LayoutFormsInput;
