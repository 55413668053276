import { ChangeEvent } from 'react';
import { connect, FormikContextType } from 'formik';

import {
    InputDecorator,
    Props as InputWrapperProps,
    ValidationActions,
} from 'components/layout/forms/InputWrapper';

import Component from './component';

export interface Props extends InputWrapperProps {
    formik?: FormikContextType<any>;
    name: string;
    className?: string;
    value?: string | number | boolean;
    onChange: (event: ChangeEvent<any>) => any;
    style?: InputStyles;
    styles?: object;
    error?: string;
    disabled?: boolean;
    validationAction?: ValidationActions;
    decoratorLeft?: InputDecorator;
    decoratorRight?: InputDecorator;
    required?: boolean;
    disableDefaultRequiredBehaviour?: boolean;

    expandable?: {
        enabled: boolean;
        label: string;
        triggerText?: string;
    };
    labelOnClick?: () => any;
}

export enum InputStyles {
    Primary = 'primary',
    Secondary = 'secondary',
    Circle = 'circle',
    Required = 'required',
    Tertiary = 'tertiary',
    BoldGreen = 'bold-green',
    Green = 'green',
    White = 'white',
    Black = 'black',
}

Component.defaultProps = {
    value: '',
    className: '',
    style: InputStyles.Primary,
    styles: {},
    error: null,
    disabled: false,
    validationAction: ValidationActions.Decorator,
    decoratorLeft: null,
    decoratorRight: null,
    expandable: null,
    required: false,
    labelOnClick: null,
};

export default connect(Component);
